import Calendar from "./calendar";
import Day from "./day";
import Styles from "./styles";

export default class DatePicker extends HTMLElement {
  format = 'MMM DD, YYYY';
  position = 'bottom';
  type = null;
  visible = false;
  date = null;
  mounted = false;
  // elements
  toggleButton = null;
  calendarDropDown = null;
  calendarDateElement = null;
  calendarDaysContainer = null;
  selectedDayElement = null;

  constructor() {
    super();

    const lang = window.navigator.language;
    const date = new Date(this.date ?? (this.getAttribute("date") || Date.now()));

    this.shadow = this.attachShadow({mode: "open"});
    this.date = new Day(date, lang);
    this.calendar = new Calendar(this.date.year, this.date.monthNumber, lang);

    this.format = this.getAttribute('format') || this.format;
    this.type = this.getAttribute('type') || this.type;
    this.position = DatePicker.position.includes(this.getAttribute('position'))
      ? this.getAttribute('position')
      : this.position;
    this.visible = this.getAttribute('visible') === ''
      || this.getAttribute('visible') === 'true'
      || this.visible;

    this.render();
  }

  connectedCallback() {
    this.mounted = true;

    this.toggleButton = this.shadow.querySelector('.date-toggle');
    this.calendarDropDown = this.shadow.querySelector('.calendar-dropdown');
    const [calendarDateElement, calendarBtns] = this.calendarDropDown
      .querySelector('.header').children;

    const [prevBtn, nextButton] = calendarBtns.children;
    this.calendarDateElement = calendarDateElement;
    this.calendarDaysContainer = this.calendarDropDown.querySelector('.month-days');

    this.toggleButton.addEventListener('click', () => this.toggleCalendar());
    prevBtn.addEventListener('click', () => this.prevMonth());
    nextButton.addEventListener('click', () => this.nextMonth());
    document.addEventListener('click', (e) => this.handleClickOut(e));

    this.renderCalendarDays();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(!this.mounted) return;

    switch(name) {
      case "date":
        this.date = new Day(new Date(newValue));
        this.calendar.goToDate(this.date.monthNumber, this.date.year);
        this.renderCalendarDays();
        this.updateToggleText();
        break;
      case "format":
        this.format = newValue;
        this.updateToggleText();
        break;
      case "visible":
        this.visible = ['', 'true', 'false'].includes(newValue)
          ? newValue === '' || newValue === 'true'
          : this.visible;
        this.toggleCalendar(this.visible);
        break;
      case "position":
        this.position = DatePicker.position.includes(newValue)
          ? newValue
          : this.position;
        this.calendarDropDown.className =
          `calendar-dropdown ${this.visible ? 'visible' : ''} ${this.position}`;
        break;
    }
  }

  toggleCalendar(visible = null) {
    if(visible === null) {
      this.calendarDropDown.classList.toggle('visible');
      this.toggleButton.classList.toggle('date-picker-btn-border');
    } else if(visible) {
      this.calendarDropDown.classList.add('visible');
      this.toggleButton.classList.add('date-picker-btn-border');
    } else {
      this.calendarDropDown.classList.remove('visible');
      this.toggleButton.classList.remove('date-picker-btn-border');
    }

    this.visible = this.calendarDropDown.className.includes('visible');

    if(this.visible) {
      this.calendarDateElement.focus();
    } else {
      this.toggleButton.focus();

      if(!this.isCurrentCalendarMonth()) {
        this.calendar.goToDate(this.date.monthNumber, this.date.year);
        this.renderCalendarDays();
      }
    }
  }

  prevMonth() {
    this.calendar.goToPreviousMonth();
    this.renderCalendarDays();
  }

  nextMonth() {
    this.calendar.goToNextMonth();
    this.renderCalendarDays();
  }

  updateHeaderText() {
    this.calendarDateElement.textContent =
      `${this.calendar.month.name} ${this.calendar.year}`;
    const monthYear = `${this.calendar.month.name} ${this.calendar.year}`
    this.calendarDateElement
      .setAttribute('aria-label', `current month ${monthYear}`);
  }

  isSelectedDate(date) {
    return date.date === this.date.date &&
      date.monthNumber === this.date.monthNumber &&
      date.year === this.date.year;
  }

  isCurrentCalendarMonth() {
    return this.calendar.month.number === this.date.monthNumber &&
      this.calendar.year === this.date.year;
  }

  selectDay(el, day) {
    if(day.isEqualTo(this.date)) return;

    this.date = day;

    if(day.monthNumber !== this.calendar.month.number) {
      this.prevMonth();
    } else {
      el.classList.add('selected');
      this.selectedDayElement.classList.remove('selected');
      this.selectedDayElement = el;
    }

    this.toggleCalendar();
    this.updateToggleText();
  }

  handleClickOut(e) {
    if(this.visible && (this !== e.target)) {
      this.toggleCalendar(false);
    }
  }

  getWeekDaysElementStrings() {
    return this.calendar.weekDays
      .map(weekDay => `<span>${weekDay.substring(0, 3)}</span>`)
      .join('');
  }

  getMonthDaysGrid() {
    const firstDayOfTheMonth = this.calendar.month.getDay(1);
    const prevMonth = this.calendar.getPreviousMonth();
    const totalLastMonthFinalDays = firstDayOfTheMonth.dayNumber - 1;
    const totalDays = this.calendar.month.numberOfDays + totalLastMonthFinalDays;
    const monthList = Array.from({length: totalDays});

    for(let i = totalLastMonthFinalDays; i < totalDays; i++) {
      monthList[i] = this.calendar.month.getDay(i + 1 - totalLastMonthFinalDays)
    }

    for(let i = 0; i < totalLastMonthFinalDays; i++) {
      const inverted = totalLastMonthFinalDays - (i + 1);
      monthList[i] = prevMonth.getDay(prevMonth.numberOfDays - inverted);
    }

    return monthList;
  }

  updateToggleText() {
    const date = this.date.format(this.format)
    this.toggleButton.textContent = date;
  }

  updateMonthDays() {
    this.calendarDaysContainer.innerHTML = '';

    this.getMonthDaysGrid().forEach(day => {
      const el = document.createElement('button');
      el.className = 'month-day';
      el.textContent = day.date;
      el.addEventListener('click', (e) => this.selectDay(el, day));
      el.setAttribute('aria-label', day.format(this.format));

      if(day.monthNumber === this.calendar.month.number) {
        el.classList.add('current');
      }

      if(this.isSelectedDate(day)) {
        el.classList.add('selected');
        this.selectedDayElement = el;
      }

      this.calendarDaysContainer.appendChild(el);
    })
  }

  renderCalendarDays() {
    this.updateHeaderText();
    this.updateMonthDays();
    this.calendarDateElement.focus();
  }

  static get observedAttributes() {
    return ['date', 'format', 'visible', 'position'];
  }

  static get position() {
    return ['top', 'left', 'bottom', 'right'];
  }

  render() {
    const monthYear = `${this.calendar.month.name} ${this.calendar.year}`;
    const date = this.date.format(this.format)
    this.shadow.innerHTML = `
      <style>${Styles()}</style>
      <button type="button" class="date-toggle">${date}</button>
      <div class="calendar-dropdown ${this.type} ${this.visible ? 'visible' : ''} ${this.position}">
        <div class="header">
            <span tabindex="0" aria-label="current month ${monthYear}">
              ${monthYear}
            </span>
            <div class='calendar-btns-wrapper'>
              <span type="button" class="prev-month" aria-label="previous month">
                <img src='images/chevron-left.svg' />
              </span>
              <span type="button" class="next-month" aria-label="next month">
                <img src='images/chevron-right.svg' />
              </span>
            </div>
        </div>
        <hr style="border: 1px solid #EAEAE9;"/>
        <div class="week-days">${this.getWeekDaysElementStrings()}</div>
        <div class="month-days"></div>
      </div>
    `
  }
}
