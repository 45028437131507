 export function handleExpandCollapse(listName){
    let listId = `#${listName}-list`
    let listPanel = `.${listName}-panel-collapse`
    let listHeading = `.${listName}-panel-heading`

    $(listId).on('show.bs.collapse', listPanel, function () {
        $(`${listPanel}.collapse`).collapse('hide');
        $(this).siblings(listHeading).addClass('active');
    });
    
    $(listId).on('hide.bs.collapse', listPanel, function () {
        $(this).siblings(listHeading).removeClass('active');
    });
}

document.addEventListener("turbo:load", () => {
    $('.gen-header-half-bg').parent().addClass('if-half-bg');
})
