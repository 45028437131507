let previousWindowWidth = window.innerWidth;

function handleResize(reload) {
    let footerPanelHeadings = document.querySelectorAll('.footer-panel-heading');
    let collapseElements = document.querySelectorAll('.footer-panel-collapse');

    if ((previousWindowWidth < 768 && window.innerWidth >= 768) ||
        (previousWindowWidth >= 768 && window.innerWidth < 768) ||
        reload) {

        previousWindowWidth = window.innerWidth;

        if (window.innerWidth < 768) {
            footerPanelHeadings.forEach(function (heading) {
                heading.classList.remove('active');
            });

            collapseElements.forEach(function (collapseElement) {
                if (collapseElement.classList.contains('show')) {
                    collapseElement.classList.remove('show');
                }
            });
        } else {
            collapseElements.forEach(function (collapseElement) {
                if (collapseElement.classList.contains('footer-panel-collapse')) {
                    collapseElement.classList.add('show');
                }
            });
        }
    }
}

window.addEventListener('resize', function () {
    handleResize(false);
});

document.addEventListener("DOMContentLoaded", function() {
    handleResize(true);
});

document.addEventListener("turbo:load", function() {
    handleResize(true);
});

document.addEventListener('turbo:load', () => {
    const backToTopButton = document.querySelector('.back-to-top');

    if (backToTopButton) {
        const backToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        backToTopButton.addEventListener('click', backToTop);
    }
});
