import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "longBillIntroduced",
    "longBillRerevised",
    "longBillRevised",
    "longBillReengrossed",
    "longBillEngrossed",
    "longBillFinalAct",
    "currentVersionSelected",
  ]

  connect() {
    if (this.hasCurrentVersionSelectedTarget) {
      this.showVersion({ target: {
        value: this.currentVersionSelectedTarget.value
      }})
    }
  }

  showVersion(e) {
    const longBillVersions = ["longBillFinalAct", "longBillRerevised", "longBillRevised", "longBillReengrossed", "longBillEngrossed", "longBillIntroduced"];
    const selectedVersionIndex = parseInt(e.target.value) || 0;
    const currentVersion = longBillVersions[selectedVersionIndex];
    const currentTarget = this.targets.find(currentVersion);

    $('.long-bill-versions').hide();

    setTimeout(() => {
      if (currentTarget) {
        currentTarget.style.display = 'block';
      }
    }, 300);
  }
}
