import moment from "moment";
import React from "react"


export const buildWeek = (date) => {
  const startOfWeek = moment(date).startOf("week");
  const endOfWeek = moment(date).endOf("week");
  let days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, "d");
  }

  return days;
}

export const mayBeGrid = (grid) => {
  if (grid !== '') return grid;

  return (
    <div className="row p-0 d-flex justify-content-center">
      <div className="col-11 px-5 no-activities-listed py-3">
        No activities listed
      </div>
    </div>
  );
}

export const activityUrl = (activityLink, scheduleItemTitle, chamber, committeeType) => {
  if (activityLink === '') return scheduleItemTitle;

  let chamber_title = isCommitteeOfReference(committeeType) ? chamber : ''

  return (
    <a href={activityLink}>
      {chamber_title} {scheduleItemTitle}
    </a>
  )
}

export const isCommitteeOfReference = (committeeType) => committeeType.includes('Reference');

export const getActiveScheduleDays = (days, noScheduleDays) => days[noScheduleDays.findIndex((el) => el === false)];
