document.addEventListener("turbo:load", function() {
  $(".initiatives-dropdown-menu a").on('click', function() {
    $('.dropdown-item').removeClass('selected-item');
    $(this).addClass('selected-item');
    $('.selected-current-status-item').text($(this).text());
    $('#current_status_form').append(`<input type="hidden" name="current_status" value="${$(this).attr('value')}" />`);
    $('#current_status_form').append(`<input type="hidden" name="election_cycle" value="${$('.selected-current-ec-item').data('value')}" />`);
    $('#current_status_form').trigger('submit');
  });

  $(".initiatives-ec-dropdown-menu a").on('click', function() {
    $('.dropdown-item').removeClass('selected-item');
    $(this).addClass('selected-item');
    $('.selected-current-ec-item').text($(this).text());
    $('#current_status_form').append(`<input type="hidden" name="current_status" value="${$('.selected-current-status-item').data('value')}" />`);
    $('#current_status_form').append(`<input type="hidden" name="election_cycle" value="${$(this).attr('value')}" />`);
    $('#current_status_form').trigger('submit');
  });
});
