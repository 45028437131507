import React from "react"

function divider_p(componentA, componentB) {
    if(componentA && componentB) {
        return <span className="divider">|</span>
    }
    return
}

const DividerMaybe = (props) => {
    const componentA = props.children[0]
    const componentB = props.children[1]
    const divider_comp = divider_p(componentA, componentB)
    return (
        <React.Fragment>
            {componentA}
            {divider_comp}
            {componentB}
        </React.Fragment>
    );
}

export default DividerMaybe