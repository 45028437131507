import DatePicker from "./calendar/date_picker";

document.addEventListener("turbo:load", function() {
  $('.budget-overview-panel-collapse .budget-filter-name').on('click', function () {
    let jqueryObject = $(this);
    let rawDOMElement = jqueryObject.get(0);
    let eventObject = $._data(rawDOMElement, 'events');

    if (eventObject != undefined) {
      if (eventObject.mouseout == undefined) {
        if (!$(this).siblings('.selected-filter-icon').hasClass('selected-budget-filter') && $(this).siblings('.selected-filter-icon').hasClass('selected-budget-filter-clicked')) {
          $(this).siblings('.selected-filter-icon').addClass('selected-budget-filter');
          $(this).on('mouseleave', function () {
            $(this).siblings('.selected-filter-icon').addClass('selected-budget-filter');
          });
        }
      } else if (eventObject.mouseout != undefined) {
        $(this).siblings('.selected-filter-icon').removeClass('selected-budget-filter');
        $(this).siblings('.selected-filter-icon').addClass('selected-budget-filter-clicked');
        $(this).off("mouseleave");
      }
    }
  });

  $('.budget-overview-panel-collapse .budget-filter-name').on('mouseenter', function () {
    $(this).siblings('.selected-filter-icon').removeClass('selected-budget-filter');
  });

  $('.budget-overview-panel-collapse .budget-filter-name').on('mouseleave', function () {
    $(this).siblings('.selected-filter-icon').addClass('selected-budget-filter');
  });

  customElements.get('date-picker') || customElements.define('date-picker', DatePicker);
});
