import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";


export default class extends Controller {
  connect() {
    // Initialize Tom Select when the controller is connected
    new TomSelect(this.element, {
      searchField: [],
      controlInput: null,

      plugins: {
          remove_button:{
              title:'Remove Item'
          }
      },

      onDropdownOpen: function() {
        let dropdown = this.dropdown;
        dropdown.classList.remove('open-upward');

        let dropdownContent = dropdown.querySelector('.ts-dropdown-content');
        let distanceToBottom = window.innerHeight - dropdownContent.getBoundingClientRect().bottom + dropdownContent.offsetHeight;

        if (distanceToBottom < dropdownContent.offsetHeight) {
          dropdown.classList.add('open-upward');
        }
      }
    });
  }
}
