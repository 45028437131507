document.addEventListener("turbo:load", function() {
  let more = document.getElementById('see-more-less')

  if (more) {
    more.addEventListener('click', function() {
      more.parentNode.parentNode.parentNode.classList.toggle('active')
      more.classList.toggle('active')
    })
  }

  var links = document.querySelectorAll(".ballot-initiatives .budget-poster-right-overview-titles");
  console.log('links', links);
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener("click", function() {
    var current = document.getElementsByClassName("active-initiative-ballot-link");
    current[0].className = current[0].className.replace(" active-initiative-ballot-link", "");
    this.className += " active-initiative-ballot-link";
    });
  }
});
