export default function Styles() {
  return `
    .date-picker-btn-border {
      box-shadow: 0 0 0 2px #fff;
    }

    button {
      text-align: start;
    }

    .date-toggle {
      padding-left: 12px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #2D73AF;
      border-radius: 4px;
      cursor: pointer;
      text-transform: capitalize;
      height: 48px;
      width: 100%;
      font-family: canada-type-gibson, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #F6F6F6;
    }

    .calendar-dropdown {
      display: none;
      width: 245px;
      position: absolute;
      left: 83%;
      transform: translate(-50%, 8px);
      padding: 24px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
      z-index: 1;
    }

    .calendar-dropdown.top {
      top: auto;
      bottom: 100%;
      transform: translate(-50%, -8px);
    }

    .calendar-dropdown.left {
      top: 50%;
      left: 0;
      transform: translate(calc(-8px + -100%), -50%);
    }

    .calendar-dropdown.right {
      top: 50%;
      left: 100%;
      transform: translate(8px, -50%);
    }

    .calendar-dropdown.visible {
      display: block;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0 18px;
      color: black;
    }

    .header span {
      margin: 0;
      text-transform: uppercase;;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #282924;
      width: 60%;
      display: flex;
      justify-content: start;
    }

    .header .prev-month {
      padding: 0;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: end;
    }

    .header .next-month {
      padding: 0;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: end;
    }

    .header .prev-month::after {
      content: '';
      display: block;
      left: 50%;
      top: 50%;
    }

    .header .next-month::after {
      content: '';
      display: block;
      left: 50%;
      top: 50%;
    }

    .week-days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 5px;
      margin-top: 22px;
      margin-bottom: 10px;
      color: #818181;
    }

    .week-days span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      text-transform: uppercase;
    }

    .month-days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 5px;
    }

    .month-day {
      padding: 8px 5px;
      color: #1F4E7D;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      cursor: pointer;
      border: none;
    }

    .month-day.current {
      background: #fff;
    }

    .month-day.selected {
      background: #1F4E7D;
      border-radius: 2px;
      color: #ffffff;
    }

    .month-day:hover {
      background: #1F4E7D;
      color: #fff;
    }

    .header .calendar-btns-wrapper {
      display: flex;
      width: 40%;
    }

    @media (max-width: 767px) {
      .calendar-dropdown.end-date-calendar {
        left: -6%;
        width: 240px;
      }

      .start-date-calendar.calendar-dropdown {
        left: 106%;
        width: 240px;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .date-toggle {
        font-size: 12px;
      }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      .start-date-calendar.calendar-dropdown {
        left: 150%;
      }
    }
  `;
}
