import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
  ]

  navigate(event) {
    const url = event.target.value;
    if (url) {
      window.location.href = url;
    }
  }

  pickADate = () => {
    this.formTarget.requestSubmit();
  }

  openLink(event) {
    const url = event.currentTarget.dataset.url;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }
}
