import { Controller } from "@hotwired/stimulus"
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import Rails from "@rails/ujs"

export default class extends Controller {
  updateQueryString = (form) => {
    const url = new URL(window.location)
    const formData = new FormData(form)
    const keysToIgnore = ["authenticity_token", "show_filters"]
    const params = url.searchParams

    Array.from(params.keys()).forEach((key) => params.delete(key))
    uniq(Array.from(formData.keys())).forEach((key) => {
      if (keysToIgnore.includes(key)) {
        return
      }

      const values = compact(formData.getAll(key))

      if (values.length) {
        values.forEach((value) => {
          if (value.length) {
            params.append(key, value)
          }
        })
      }
    })

    window.history.pushState({ customHistory: true }, "", url)
  }  
  
  legislator_ajax_call(){
        Rails.ajax({
            type:"GET",
            url: "/sort_filter_legislators",
            data: new URLSearchParams([['chamber', $("#chamber-dropdown-item option:selected").val()],
                ['party', $("#party-dropdown-item option:selected").val()],
                ['sort', $("#legislator-sort-dropdown-item option:selected").val()]]).toString(),
            dataType: "json",
            success:function(result){
              //this appends the turbo stream response to the page
              document.getElementById('leg-sort-filter-results').innerHTML = result
            }
          })
    }

    sponsoredBillsCall(mobile){
        //Trigger the desktop or mobile submit logic depending on which UI is being used.
        let formId = (mobile ? "#bill_sort_and_filter_mobile_form" : "#bill-sort-and-filter-form")
        let form = $(formId)
        form.trigger("submit");
    }

    allBillsCall(dropdown){
      let formId = dropdown.getAttribute('form');
      let form = document.getElementById(formId);
      this.updateQueryString(form); 
      form.requestSubmit();
  }

    updateSortFilterList(event) {
        const select = event.target;
        const selectedIndex = select.selectedIndex;
        const selectedOption = select.options[selectedIndex];
        const sortfilter_type = this.data.get("sortfilterType");

        // Update the data-value attribute
        const dropdownHeading = document.getElementById(sortfilter_type);
        if (dropdownHeading) {
          dropdownHeading.setAttribute('data-value', selectedOption.value);
        }

        // Remove the styling of the previous selected element
        const previousSelectedOption = select.querySelector('option[selected]');
        if (previousSelectedOption) {
          previousSelectedOption.removeAttribute('selected');
        }

        // Update the styling of the newly selected option
        if (selectedOption) {
          selectedOption.setAttribute('selected', 'selected');
        }

        // Send AJAX call to update content
        switch (sortfilter_type) {
          case "legislators":
            this.legislator_ajax_call();
            break;
          case "sponsored-bills":
            this.sponsoredBillsCall(false);
            break;
          case "sponsored-bills-mobile":
            this.sponsoredBillsCall(true);
            break;
          case "all-bills":
            this.allBillsCall(event.target)
            break;
          // Add other cases here
        }
    }
}
