document.addEventListener("turbo:load", function() {
  $(function () {
    $(".section-with-light-purple-bg")
      .parent()
      .css({ backgroundColor: '#EFF5FF' })
  })

  $(function () {
    $(".section-with-off-white-bg")
      .parent()
      .css({ backgroundColor: 'F6F6F6' })

    $(".section-with-light-gray-bg")
      .parent()
      .css({ backgroundColor: '#F6F6F6;' })
  })
});
