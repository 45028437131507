import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["billSummaryBlock", "readMoreLessBlock", "billSummaryHeading"]

  connect() {
    if (this.hasBillSummaryBlockTarget) {
      $(this.billSummaryBlockTarget).find('p').css({
        textIndent: 0,
      });

      this.checkSummaryHeight();
    }
  }

  toggle(event) {
    const summaryBlock = $(this.billSummaryBlockTarget);
    const button = event.currentTarget;

    summaryBlock.toggleClass('collapsed');
    $(button).toggleClass('collapsed');
    $(button.parentNode).toggleClass('collapse-summary');

    if (summaryBlock.hasClass('collapsed')) {
      button.textContent = 'Read more';
      this.scrollToElement(this.billSummaryHeadingTarget);
    } else {
      button.textContent = 'Read less';
    }
  }

  openLink(event) {
    const url = event.currentTarget.dataset.url;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  switchTab = (e) => {
    setTimeout(() => {
      $('.tab-pane.fade').removeClass('active show');
      $('#' + `${e.target.value}`).addClass('active show');
    }, 400);
  }

  scrollToElement = (selector) => {
    let targetOffset = $(selector).offset().top;

    window.scrollTo({
      top: targetOffset,
      behavior: 'smooth',
    });
  }

  checkSummaryHeight() {
    const summaryBlock = $(this.billSummaryBlockTarget);
    const lineHeight = parseFloat(summaryBlock.css('line-height'));
    const blockHeight = summaryBlock.height();
    const lineCount = blockHeight / lineHeight;

    if (lineCount <= 12) {
      $(this.readMoreLessBlockTarget).hide();
      summaryBlock.removeClass('collapsed');
    } else {
      $(this.readMoreLessBlockTarget).show();
      summaryBlock.addClass('collapsed');
    }
  }
}
