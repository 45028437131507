document.addEventListener("turbo:load", function() {
  const el = document.getElementById('bill-summary-description-text');
  if (el) {
    const divHeight = +el.offsetHeight
    const lineHeight = +el.style.lineHeight.replace('px', '');
    const lines = parseInt(divHeight / lineHeight);

    if (lines < 10) {
      $('#bill-summary-description-text').removeClass('height-for-inactive-read-more');
      $('#bill-summary-description-text').removeClass('bill-summary-description');
      $('#more-less').removeClass('read-more-less');
    }
  }

  let more = document.getElementById('more-less')

  if (more) {
    let windowHeight = window.innerHeight;

    more.addEventListener('click', function() {
      $(more).toggleClass('active');
      more.parentNode.parentNode.classList.toggle('active')
      document.getElementById('bill-summary-description-text').classList.toggle('height-for-inactive-read-more')

      if (!$(more).hasClass('active')) {
        window.scrollTo({
          top: windowHeight,
          behavior: 'smooth',
        });
      }
    })
  }

  $('#allBillsDataFiltersBtn').on('click', function() {
    $('.nav-trigger-btn').hide();
  });

  $('#allBillsDataFiltersCloseBtn').on('click', function() {
    $('.nav-trigger-btn').show();
  });

  // The below two code locks jumps the user to a passed in tab id
  var hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
  if (hash) {
    if(window.innerWidth < 1200 && hash.indexOf("committee-activity") > -1){
      console.log(`Page Size: ${window.innerWidth}`)
      scrollToMobileBillSection("#viewBySessionDropdownMain", "committees")
      showSelectedTabSection("committees")
    }
    else {
      scrollToHashElement(hash)
    }
  }
});

function scrollToHashElement(hash){
  $('.nav-tabs a[href="#' + hash + '"]').tab('show');
  var element = document.getElementById('#' + hash);
  if (element) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest'
    });
  }
}

function scrollToMobileBillSection(billSection, billTab){
  showSelectedTabSection(billTab)
  const elementToScrollTo = $(billSection);
  $(window).scrollTop(elementToScrollTo.offset().top);
}

function showSelectedTabSection(currentTab) {
  let currentTabCapitalized = currentTab.charAt(0).toUpperCase() + currentTab.slice(1)
  $("#viewBySessionDropdown .dropdown-heading").text(currentTabCapitalized)
}
