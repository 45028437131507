// app/javascript/controllers/session_schedule_controller.js
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["form"];

  connect() {}

  changeWeek = (event) => {
    const date = event.currentTarget.dataset.date;

    if (date) {
      let input = this.formTarget.querySelector('input[name="date"]');

      if (!input) {
        input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'date';
        this.formTarget.appendChild(input);
      }
      input.value = date;

      let current_date_input = this.formTarget.querySelector('input[name="current_date"]');

      if (!current_date_input) {
        current_date_input = document.createElement('input');
        current_date_input.type = 'hidden';
        current_date_input.name = 'current_date';
        this.formTarget.appendChild(current_date_input);
      }

      current_date_input.value = date;
    }


    let weekInput = this.formTarget.querySelector('input[name="week_change"]');

    if (!weekInput) {
      weekInput = document.createElement('input');
      weekInput.type = 'hidden';
      weekInput.name = 'week_change';
      this.formTarget.appendChild(weekInput);
    }

    weekInput.value = true;

    this.formTarget.requestSubmit();
    this.scrollToElement('#session-schedule-records-form');
  }

  changeViewOrOrigin = () => {
    let weekInput = this.formTarget.querySelector('input[name="week_change"]');

    if (!weekInput) {
      weekInput = document.createElement('input');
      weekInput.type = 'hidden';
      weekInput.name = 'week_change';
      this.formTarget.appendChild(weekInput);
    }

    weekInput.value = false;

    this.formTarget.requestSubmit();
    this.scrollToElement('#session-schedule-records-form');
  }

  changeDay = (event) => {
    let date = event.currentTarget.dataset.currentDate;

    if (event.target.tagName === 'SELECT') {
      date ||= event.target.value;
    }

    if (date) {
      let input = this.formTarget.querySelector('input[name="current_date"]');

      if (!input) {
        input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'current_date';
        this.formTarget.appendChild(input);
      }

      input.value = date;
    }

    let weekInput = this.formTarget.querySelector('input[name="week_change"]');

    if (!weekInput) {
      weekInput = document.createElement('input');
      weekInput.type = 'hidden';
      weekInput.name = 'week_change';
      this.formTarget.appendChild(weekInput);
    }

    weekInput.value = false;

    this.formTarget.requestSubmit();
    this.scrollToElement('#session-schedule-records-form');
  }

  changeDayOrWeek = (event) => {
    const date = event.currentTarget.dataset.currentDate;
    const changeWeek = event.currentTarget.dataset.changeWeek;

    if (date) {
      let input = this.formTarget.querySelector('input[name="current_date"]');

      if (!input) {
        input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'current_date';
        this.formTarget.appendChild(input);
      }

      input.value = date;
    }

    if (changeWeek) {
      let date_input = this.formTarget.querySelector('input[name="date"]');

      if (!date_input) {
        date_input = document.createElement('input');
        date_input.type = 'hidden';
        date_input.name = 'date';
        this.formTarget.appendChild(date_input);
      }
      date_input.value = date;

      let weekInput = this.formTarget.querySelector('input[name="week_change"]');

      if (!weekInput) {
        weekInput = document.createElement('input');
        weekInput.type = 'hidden';
        weekInput.name = 'week_change';
        this.formTarget.appendChild(weekInput);
      }

      weekInput.value = false;
    }

    this.formTarget.requestSubmit();
    this.scrollToElement('#session-schedule-records-form');
  }

  scrollToElement = (selector) => {
    let targetOffset = $(selector).offset().top;

    window.scrollTo({
      top: targetOffset,
    });
  }
}
