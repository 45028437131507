import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  connect() {
    document.dispatchEvent(new CustomEvent('dataLoaded', { detail: 'result' }));
  }

  submitPaginationPage(event){
    const paginationType = this.data.get("paginationType")

    switch(paginationType) {
      case 'sponsoredBills':
        this.submitSponsoredBills(event);
        break;
        case 'billSearch':
        this.submitBillSearch(event);
        break;
    }
  }

  paginateArrowNav(event){
    //This alters the url for the selected pagination link. The altered link will only return the partial with the
    //turbo frame content, negating the need to refresh the entire page.
    //If there is a way to alther the next/previous links through kaminari, that would be perferable.
    const paginationType = this.data.get("paginationType")
    let newPath =  this.getAlteredUrl(paginationType)
    if (newPath == null) return
    let origin = window.location.origin
    let newUrl = new URL(newPath, origin)
    let link = event.target
    let searchParams = new URL(link.getAttribute("href"), origin).searchParams
    searchParams.forEach((value, key) => {
      newUrl.searchParams.append(key, value);
    });
    link.setAttribute('href', newUrl.toString());
  }

  submitSponsoredBills(event){
    if(event.key!== 'Enter') return
    
    let form = $("#sponsored_bills_tag #pagination-page-selector")
    let filters = $("#bill-sort-and-filter-form input:checked")
    filters.each(function() {
      let newInput= document.createElement("input");
      newInput.type="hidden";
      newInput.name= this.name
      newInput.value = this.value
      form.append(newInput)
    });
    //The below code should be imported from apply_bill_search_filters.js
    //However, I'm having issues importing it.
    let perInput = document.createElement("input");
    perInput.type= "hidden";
    perInput.name= "per";
    perInput.value= $(".bill-per-page-dropdown-item").attr('data-value');
    form.append(perInput);

    let sortInput = document.createElement("input");
    sortInput.type= "hidden";
    sortInput.name= "sort";
    sortInput.value= $(".bill-sort-dropdown-item").attr('data-value');
    form.append(sortInput);

    form.attr('data-turbo-frame', 'sponsored_bills_tag')
  }

  getAlteredUrl(paginationType) {
    //Returns altered URL based on paginationType
    //The altered URL will return the turbo frame, and only the turbo frame, increasing refresh speed.
    switch(paginationType){
      case "billSearch": 
        return "/bills/bill-search-results"
      default:
        return null;
    }
  }
}