import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
  }
  
  var h2ButtonHTML = '<button type="button" data-trix-attribute="heading2" title="Subheading">H2</button>'
  var groupElement = Trix.config.toolbar.content.querySelector(".block_tools")
  groupElement.insertAdjacentHTML("beforeend", h2ButtonHTML)
  }
}
