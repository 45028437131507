import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const defaultConfig = {
      searchField: [],
      controlInput: null,
    };

    if (this.element.id === 'committee-hearing-pick-a-date') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        },
        item: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        }
      };
    }

    // Initialize Tom Select when the controller is connected
    const tomSelectInstance = new TomSelect(this.element, defaultConfig);

    if (Object.keys(tomSelectInstance.options).length > 6) {
      tomSelectInstance.dropdown_content.classList.add('ts-scrollable-dropdown');
    }
  }
}
