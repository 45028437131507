import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  removeSearchResult(){
    const search_results_list = document.getElementById('search-results-list')
    if(search_results_list){
      //clear input box
      document.getElementById('search_legislators').value = ""
      //remove search results
      search_results_list.remove()
    }
  }

  clickOutsideSearchRemove(evt) {
    const results_list = document.getElementById("search-results-list");
    let clicked_element = evt.target; // clicked element      
    do {
      if(clicked_element == results_list) {
        // This is a click inside, does nothing, just return.
        return;
      }
      // Go up the DOM
      clicked_element = clicked_element.parentNode;
    } while (clicked_element);
    // This is a click outside.      
    this.removeSearchResult();
  }

    initialize(){
      document.addEventListener('click', (event) => { 
        this.clickOutsideSearchRemove(event)
      });
    } 

    //this opens the selected legislator if the user clicks enter when focus is inside input field
    openResultPage(event){
      event.stopPropagation();
      event.preventDefault();
      var highlighted_elements_array = document.getElementsByClassName("highlight-legislator")
      
      if(highlighted_elements_array.length == 1){
        //the array will contain list items but we want to click the anchor so get its parent
        highlighted_elements_array[0].parentNode.click();
      }
      
      this.removeSearchResult()
    }
  
    legislator_submit(){
      const input_for_search = document.getElementById('search_legislators').value
          if (input_for_search.trim() === ""){
            this.removeSearchResult();
          }else{
            Rails.ajax({
              type:"GET",
              url: "/search_legislators",
              data: new URLSearchParams({'search_legislators': document.getElementById('search_legislators').value}).toString(),
              dataType: "json",
              success:function(result){
                //this appends the turbo stream response to the page
                document.getElementById('leg-search-results').innerHTML = result
              }
            })
          }
    }
 
    // either enter key was hit and we're opening the selected legislator page
    // or the legislator list item was clicked and we're navigating to page that was clicked on
    submit(event) {
      const search_type = this.data.get("searchType")
      
      if(event.type === "input"){
        //case for legislators
        if(search_type == "legislator"){
          this.legislator_submit();
        }
        //insert other search ajax method calls here
      }
    }


    //this function handles the hover highlight functionality of the list of legislators 
    handleMouseIn(event) {
      //searchType in this class is Legislator but can be
      //reused for other searches by changing the searchType
      const search_type = this.data.get("searchType")
      const highlight_class_name = "highlight-" + search_type

      var highlighted_elements_array = document.getElementsByClassName(highlight_class_name)
      //remove class from any list item (li) that previously had it - we only want ONE list item 
      //highlighted at a time 
      for (var i = 0, len = highlighted_elements_array.length; i < len; i++ ) {
        highlighted_elements_array[i].classList.remove(highlight_class_name);
      }

      // if we're hovering and catch the anchor element instead of the list item element
      // we want to get the list item from it (using parentNode) so that we can add
      // the class to the list item
      if(event.target.tagName == "A"){
        event.target.parentNode.classList.add(highlight_class_name)
      }
       // in this case we hovered over the list item and just need to add the class directly
      else{
        event.target.classList.add(highlight_class_name)
      }
    }
}