import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["session", "sortby"];

  connect() {
    console.log("filters controller started");
  }

  filter() {
    const url = `${window.location.pathname}?${this.params}`;

    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  params() {
    return [this.session, this.sortby].join("&");
  }

  session() {
    return `session_id=${this.sessionTarget.value}`;
  }

  sortby() {
    return `sortby=${this.sortbyTarget.value}`;
  }
}
