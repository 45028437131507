import {customDropdownLogic} from "./custom_dropdown"
import Rails from "@rails/ujs"
import { disableApplyFiltersBtn} from "./apply_bill_search_filters";
import { event } from "jquery"

document.addEventListener("turbo:load", function() {
  $("#session-filter-dropdown").on('change', function() {
    $('#gen_session_form').trigger('submit');
  });

  $(".chamber-form-dropdown a").on('click', function() {
    $('.dropdown-item').removeClass('selected-item');
    $(this).addClass('selected-item');
    $('.chamber-dropdown-item').text($(this).text());
    $('#chamber_form').append(`<input type="hidden" name="chamber" value="${$(this).attr('value')}" />`);
    $('#chamber_form').append(`<input type="hidden" name="party" value="${$('.party-dropdown-item').data('value')}" />`);
    $('#chamber_form').append(`<input type="hidden" name="sort" value="${$('.legislator-sort-dropdown-item').data('value')}" />`);
    $('#chamber_form').trigger('submit');
  });

  $(".party-form-dropdown a").on('click', function() {
    $('.dropdown-item').removeClass('selected-item');
    $(this).addClass('selected-item');
    $('.party-dropdown-item').text($(this).text());
    $('#party_form').append(`<input type="hidden" name="chamber" value="${$('.chamber-dropdown-item').data('value')}" />`);
    $('#party_form').append(`<input type="hidden" name="party" value="${$(this).attr('value')}" />`);
    $('#party_form').append(`<input type="hidden" name="sort" value="${$('.legislator-sort-dropdown-item').data('value')}" />`);
    $('#party_form').trigger('submit');
  });

  $(".legislator-sort-form-dropdown a").on('click', function() {
    $('.dropdown-item').removeClass('selected-item');
    $(this).addClass('selected-item');
    $('.legislator-sort-dropdown-item').text($(this).text());
    $('#legislators_sort_form').append(`<input type="hidden" name="chamber" value="${$('.chamber-dropdown-item').data('value')}" />`);
    $('#legislators_sort_form').append(`<input type="hidden" name="party" value="${$('.party-dropdown-item').data('value')}" />`);
    $('#legislators_sort_form').append(`<input type="hidden" name="sort" value="${$(this).attr('value')}" />`);
    $('#legislators_sort_form').trigger('submit');
  });

  $(".sponsored-bills-search-form input[type='checkbox']").on('click', function(event){
    $('.sponsored-bills-search-form #' + this.id ).prop("checked", this.checked);
  });

  $(".sponsored-bills-search-form").on('submit', function(event) {
    event.preventDefault()
    let form = event.target
    let fields = $(`#${form.id}`).serialize()
    const queryParams = new URLSearchParams(fields);
    const path = form.getAttribute("action") + '?' + queryParams.toString();
    $('#sponsored_bills_tag').attr("busy",'');

    getFilteredBills(path)

    $('[name="sort"],[name="per"]').remove()
    $("#sponsorBillResolutionFilters").modal('hide')
  });

  $('.house-term-page-paginator .page-number-input-wrapper .page-input').addClass('page-input-with-box-shadow')
});


function getFilteredBills(path){
  fetch(path, {
    method: "GET",
    headers: {
      Accept: "text/html"
    }
  })
  .then(response => response.text())
  .then(result => {
    if (result && result.includes("turbo_stream")) {
      Turbo.renderStreamMessage(result);
    } else {
      // Handle other types of responses, if needed
      $("#sponsors-bill-and-resolution-list").html(result);
    }

    setTimeout(function() {
      const event = new CustomEvent('dataLoaded', { detail: 'result' });
      document.dispatchEvent(event);
    }, 1000);

    $('#sponsored_bills_tag').removeAttr("busy",'');
    disableApplyFiltersBtn("apply-filters-btn");
  })
  .catch(error => {
    console.error("Error fetching sponsored bills:", error);
  });
}
