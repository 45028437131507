import React, { useState } from "react"
import arrowLeft from "./../../assets/images/icons/arrow-left.svg"
import arrowRight from "./../../assets/images/icons/arrow-right.svg"

const Pagination = ({ perPage, totalCount, paginate, currentPage, currentPageDataCount }) => {
  const handleChange = (event) => {
    paginate(event.target.value)
  }

  const gotToNextPage = () => {
    paginate(parseInt(currentPage) + 1)
  }

  const gotToPrevPage = () => {
    paginate(parseInt(currentPage) - 1)
  }

  const totalPages = () => Math.ceil(totalCount / perPage)

  return (
    <div className="row p-0 d-flex justify-content-between">
      <div className="col-md-6 col-12 d-flex align-items-center px-0">
        Displaying {currentPageDataCount} of {totalCount} results
      </div>
      <div className="col-md-6 col-12 d-flex justify-content-md-end px-0 pt-md-0 pt-3">
        <div className="row p-0">
          <div className="col-md-2"></div>
          <div className="col-md-3 col-4 p-0 d-flex justify-content-start">
            <button
              className="btn btn-outline-primary btn-md pagination-btn pl-md-3"
              onClick={gotToPrevPage}
              disabled={currentPage <= 1 ? true : ""}
            >
              {" "}
              <span>
                <img src={arrowLeft} alt="arrowLeft" />{" "}
              </span>{" "}
              Prev
            </button>
          </div>
          <div className="col-md-2 col-2 px-0 pl-md-1">
            <input
              type="number"
              min={1}
              name="name"
              className="w-100 h-100 text-center pagination-input border border-0"
              value={currentPage}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-2 col-3 px-1 d-flex align-items-center of-total-pages justify-content-around">
            <div>
            of
            </div>
            <div>
              {totalPages()}
            </div>
          </div>
          <div className="col-md-3 col-3 p-0 d-flex justify-content-end">
            <button
              className="btn btn-outline-primary btn-md pagination-btn pr-md-3"
              onClick={gotToNextPage}
              disabled={currentPage >= totalPages() ? true : ""}
            >
              Next{" "}
              <span>
                <img src={arrowRight} alt="arrowRight" />{" "}
              </span>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pagination
