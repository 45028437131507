import {handleExpandCollapse} from "../common"
$(document).on("turbo:load", function() {
  $('.committee-details-panel-collapse').on('show.bs.collapse', function () {
    $('.committee-details-panel-collapse.collapse').collapse('hide');
    $(this).siblings('.committee-details-panel-heading').addClass('active');
  });

  $('.committee-details-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.committee-details-panel-heading').removeClass('active');
  });

  $('.budget-overview-panel-collapse').on('show.bs.collapse', function () {
    $('.budget-overview-panel-collapse.collapse').collapse('hide');
    $(this).siblings('.budget-overview-panel-heading').addClass('active');
  });

  $('.budget-overview-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.budget-overview-panel-heading').removeClass('active');
  });

  $('.committee-activity-panel-collapse').on('show.bs.collapse', function () {
    $('.committee-activity-panel-collapse.collapse').collapse('hide');
    $(this).siblings('.committee-activity-panel-heading').addClass('active');
  });

  $('.committee-activity-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.committee-activity-panel-heading').removeClass('active');
  });

  $('.pub-summary-panel-collapse').on('show.bs.collapse', function () {
    $('.pub-summary-panel-collapse.collapse').collapse('hide');
    $(this).siblings('.publication-detail-panel-heading').addClass('active');
  });

  $('.pub-summary-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.publication-detail-panel-heading').removeClass('active');
  });

  $('.legislator-detail-panel-collapse').on('show.bs.collapse', function () {
    $(this).siblings('.legislator-detail-panel-heading').addClass('active');
  });

  $('.legislator-detail-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.legislator-detail-panel-heading').removeClass('active');
  });

  $('.footer-panel-collapse').on('show.bs.collapse', function () {
    $(this).siblings('.footer-panel-heading').addClass('active');
  });

  $('.footer-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.footer-panel-heading').removeClass('active');
  });

  $('.all-bills-panel-collapse').on('show.bs.collapse', function () {
    $(this).siblings('.all-bills-panel-heading').addClass('active');
  });

  $('.all-bills-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.all-bills-panel-heading').removeClass('active');
  });

  handleExpandCollapse("sponsors-bill-and-resolution")
  handleExpandCollapse("all-bills-data")

  $('.legislators-data-panel-collapse').on('show.bs.collapse', function () {
    $('.legislators-data-panel-collapse.collapse').collapse('hide');
    $(this).siblings('.legislators-data-panel-heading').addClass('active');
    $(this).siblings('.legislators-data-panel-heading').removeClass('pb-4');
  });

  $('.legislators-data-panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.legislators-data-panel-heading').removeClass('active');
    $(this).siblings('.legislators-data-panel-heading').addClass('pb-4');
  });

  $(document).on('change', '.div-toggle', function() {
    var target = $(this).data('target');
    var show = $("option:selected", this).data('show');
    $(target).children().addClass('hide');
    $(show).removeClass('hide');
  });

  $(document).ready(function() {
    $('.div-toggle').trigger('change');
  });
});

//The below code rebinds the event listeners to the sponsored bills cards after the asynchronous data load.
function handleDataLoaded() {
  handleExpandCollapse("sponsors-bill-and-resolution")
  handleExpandCollapse("all-bills-data")
}

document.addEventListener('dataLoaded', handleDataLoaded);
