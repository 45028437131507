import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["finalact", "introduced","revised","rerevised","engrossed","reengrossed","longbillversiontab"];
  connect() {
    this.sessionlawsTargets.forEach((item, index, array) => {
      if (index !== array.length - 1){
        item.style.display = 'none';
      }
    });
  }
  showversion(e) {
    const selectedVersion = e.target.value;
    var element = document.getElementById("sessionlaws_" + selectedVersion);
    this.sessionlawsTargets.forEach(item => {
      item.style.display = 'none';
    });
    if (element) {
      element.style.display = 'block';
    }
  }
}
